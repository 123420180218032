import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const Welcome1 = () => {
    
  return (
    <div>
        <Box  >
            
                    <Text fontSize={'28px'} fontWeight={"bold"} width={'170px'}  color={'black'} >

                        ABOUT US

                    </Text>
            
        </Box>
    </div>
  )
}

export default Welcome1